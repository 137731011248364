import easyToggle from "easy-toggle-state";

const initScripts = () => {
	easyToggle();

	$('input[type="tel"]').each(function() {
		var $input;
		$input = $(this);
		return window.intlTelInput($input.get(0), {
			initialCountry: "auto",
			geoIpLookup: function(success, failure) {
				return $.getJSON('https://ipapi.co/json/', function(data) {
					return success(data.country_code);
				});
			},
			nationalMode: false,
			autoPlaceholder: "aggressive",
			showSelectedDialCode: true,
			utilsScript: "assets/vendors/utils.js",
		});
	});
};

initScripts();

const footer = () => {
	const block = document.querySelector(".footer");
	if (!block) {
		return;
	}
	const copyrightYear = block.querySelector(".footer__copyright-year");
	const date = new Date();

	copyrightYear.innerText = date.getFullYear();
};
footer();

const countdownTimer = () => {
	const $block = $('.countdown-timer');
	if (!$block.length) {
		return;
	}
	$block.each(function () {
		const time = $block.data("time");

		const dayLabel = $(this).data('day');
		const hourLabel = $(this).data('hour');
		const minutesLabel = $(this).data('minutes');
		const secondsLabel = $(this).data('seconds');

		if (time) {
			$(this).countdown(time, function (event) {
				const $wrap = $(this).find('.countdown-timer__wrap');
				$wrap.html(event.strftime(`
					<div class="countdown-timer__digit">
						<div class="countdown-timer__number">%D</div>
						<div class="countdown-timer__label">${dayLabel}</div>
					</div>
					<div class="countdown-timer__digit">
						<div class="countdown-timer__number">%H</div>
						<div class="countdown-timer__label">${hourLabel}</div>
					</div>
					<div class="countdown-timer__digit">
						<div class="countdown-timer__number">%M</div>
						<div class="countdown-timer__label">${minutesLabel}</div>
					</div>
					<div class="countdown-timer__digit">
						<div class="countdown-timer__number">%S</div>
						<div class="countdown-timer__label">${secondsLabel}</div>
					</div>
				`));
			});
		} else {
			$(this).addClass('countdown-timer_hide');
		}
	});
};

countdownTimer();


// validation
$(function () {
	const $block = $('.validation-wrap');
	if (!$block) {
		return;
	}
	const $form = $block.find('form');
	const $this = $(this);

	$form.submit(function (e) {
		e.preventDefault();
		let validation = true;

		$(this).find('[required]').each(function () {
			let checked;
			if ($(this).val() === '') {
				validation = false;
				$(this).closest('.form-group').addClass('has-error');
			}
			if ($(this).is('[type="checkbox"]')) {
				checked = $(this).prop('checked');
				if (!checked) {
					validation = false;
					$(this).closest('.form-group').addClass('has-error');
				}
			}
		});

		$(this).find('input[type="email"]').each(function () {
			let emailReg;
			if ($(this).val() !== '') {
				emailReg = /^.+@.+\..+$/;
				if (!emailReg.test($(this).val())) {
					validation = false;
					$(this).closest('.form-group').addClass('has-error');
				}
			}
		});

		$(this).find('input[type="tel"]').each(function() {
			const iti = window.intlTelInputGlobals.getInstance($(this).get(0));
			if (!iti.isValidNumberPrecise()) {
				validation = false;
				$(this).closest(".form-group").addClass("has-error");
			}
		});

		if (validation) {
			const $formWrap = $(this).closest('.validation-wrap');
			$formWrap.addClass('submitting');
			setTimeout(function () {
				$formWrap.removeClass('submitting').addClass('submitted');
			}, 1000);
			$(this).trigger('validation-submit', $(this));
		} else {
			$(this).trigger('validation-error', $(this));
		}
	});

	$(document).on('click focus', '.has-error input, .has-error textarea', function (e) {
		$(e.currentTarget).closest('.has-error').removeClass('has-error');
	});

	if ($this.find('.validation-wrap').hasClass('submitted')) {
		$this.find('form').get(0).reset();
	}

	$this.find('.has-error').removeClass('has-error');
	$this.find('.validation-wrap').removeClass('submitted');
});
